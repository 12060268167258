import * as React from "react"
import { Container, Row, Col, Card } from "react-bootstrap"
import Slider from "react-slick";

// Styles
import "./AvailableApartments.scss"
import CustomButton from "../../../components/CustomButton"

// Components
import SectionWrapper from "../../../components/SectionWrapper"
import SectionHeader from "../../../components/SectionHeader"
import NoImage from "../../../images/no-image.png"

const AvailableApartments = (props) => {

    // Slider settings
    const settings = {
        dots: false,
        speed: 800,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: false,
        arrows: true,
        mobileFirst: true,
        responsive: [
             {
            breakpoint: 9999,
            
            },
            {
                breakpoint: 1600,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 1,
                  infinite: false,
                  dots:false,
                  arrows: true,
                },
              },
            {
                breakpoint: 1199,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 1,
                  infinite: false,
                  dots:false,
                  arrows: true,
                },
              },
            {
                breakpoint: 991,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                  infinite: false,
                  dots:false,
                },
              },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: false,
              dots:false,
            },
          },
        ],
      }
    // Slider settings

    return (
        <React.Fragment>
            <SectionWrapper className="">
                <Container>
                    <div className="property-cards-wrapper available-apartments-slider">
                        <Row>
                            <Col lg={12}>
                                <div className="available-aprtmt-title mb-4">Available apartments to rent in {props?.propertyData?.display_address && props?.propertyData?.display_address}</div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <div className="d-flex justify-content-between available-properties-wrapper mb-4">
                                    <div className="available-properties">1 Bedroom Properties <span>(6 Available)</span></div>
                                    <div className="available-price">from <span>£550 p/w -</span></div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <Slider {...settings}>
                                    <div className="single-property-card-wrapper">
                                        <Card>
                                            <div className="card-container-image">
                                                <span className="image-label">
                                                    { "For Sale" }
                                                </span>
                                                <div class="card-container-image">
                                                    <img className={"img-fluid"} src={NoImage} alt={""} title={""} />
                                                </div>
                                            </div>
                                            <Card.Body>
                                                <Card.Title className="card-title">
                                                    { "Hill Street, W1, London" } 
                                                </Card.Title>
                                                <Row className="card-content">
                                                    <Col sm={ 5 } xs={ 5 } className="card-subtitle">
                                                        £{ "500" } p/w
                                                    </Col>
                                                    <Col>
                                                        <div className="card-footer-icons">
                                                            <div className="count-icons">
                                                                <i className="icon icon-bedroom"></i>
                                                                <span>{ "2" }</span>
                                                            </div>
                                                            <div className="count-icons">
                                                                <i className="icon icon-bathroom"></i>
                                                                <span>{ "3" }</span>
                                                            </div>
                                                            <div className="count-icons">
                                                                <i className="icon icon-pet-friendly"></i>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className="single-property-card-wrapper">
                                        <Card>
                                            <div className="card-container-image">
                                                <span className="image-label">
                                                    { "For Sale" }
                                                </span>
                                                <div class="card-container-image">
                                                    <img className={"img-fluid"} src={NoImage} alt={""} title={""} />
                                                </div>
                                            </div>
                                            <Card.Body>
                                                <Card.Title className="card-title">
                                                    { "Hill Street, W1, London" } 
                                                </Card.Title>
                                                <Row className="card-content">
                                                    <Col sm={ 5 } xs={ 5 } className="card-subtitle">
                                                        £{ "500" } p/w
                                                    </Col>
                                                    <Col>
                                                        <div className="card-footer-icons">
                                                            <div className="count-icons">
                                                                <i className="icon icon-bedroom"></i>
                                                                <span>{ "2" }</span>
                                                            </div>
                                                            <div className="count-icons">
                                                                <i className="icon icon-bathroom"></i>
                                                                <span>{ "3" }</span>
                                                            </div>
                                                            <div className="count-icons">
                                                                <i className="icon icon-pet-friendly"></i>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className="single-property-card-wrapper">
                                        <Card>
                                            <div className="card-container-image">
                                                <span className="image-label">
                                                    { "For Sale" }
                                                </span>
                                                <div class="card-container-image">
                                                    <img className={"img-fluid"} src={NoImage} alt={""} title={""} />
                                                </div>
                                            </div>
                                            <Card.Body>
                                                <Card.Title className="card-title">
                                                    { "Hill Street, W1, London" } 
                                                </Card.Title>
                                                <Row className="card-content">
                                                    <Col sm={ 5 } xs={ 5 } className="card-subtitle">
                                                        £{ "500" } p/w
                                                    </Col>
                                                    <Col>
                                                        <div className="card-footer-icons">
                                                            <div className="count-icons">
                                                                <i className="icon icon-bedroom"></i>
                                                                <span>{ "2" }</span>
                                                            </div>
                                                            <div className="count-icons">
                                                                <i className="icon icon-bathroom"></i>
                                                                <span>{ "3" }</span>
                                                            </div>
                                                            <div className="count-icons">
                                                                <i className="icon icon-pet-friendly"></i>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className="single-property-card-wrapper">
                                        <Card>
                                            <div className="card-container-image">
                                                <span className="image-label">
                                                    { "For Sale" }
                                                </span>
                                                <div class="card-container-image">
                                                    <img className={"img-fluid"} src={NoImage} alt={""} title={""} />
                                                </div>
                                            </div>
                                            <Card.Body>
                                                <Card.Title className="card-title">
                                                    { "Hill Street, W1, London" } 
                                                </Card.Title>
                                                <Row className="card-content">
                                                    <Col sm={ 5 } xs={ 5 } className="card-subtitle">
                                                        £{ "500" } p/w
                                                    </Col>
                                                    <Col>
                                                        <div className="card-footer-icons">
                                                            <div className="count-icons">
                                                                <i className="icon icon-bedroom"></i>
                                                                <span>{ "2" }</span>
                                                            </div>
                                                            <div className="count-icons">
                                                                <i className="icon icon-bathroom"></i>
                                                                <span>{ "3" }</span>
                                                            </div>
                                                            <div className="count-icons">
                                                                <i className="icon icon-pet-friendly"></i>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className="single-property-card-wrapper">
                                        <Card>
                                            <div className="card-container-image">
                                                <span className="image-label">
                                                    { "For Sale" }
                                                </span>
                                                <div class="card-container-image">
                                                    <img className={"img-fluid"} src={NoImage} alt={""} title={""} />
                                                </div>
                                            </div>
                                            <Card.Body>
                                                <Card.Title className="card-title">
                                                    { "Hill Street, W1, London" } 
                                                </Card.Title>
                                                <Row className="card-content">
                                                    <Col sm={ 5 } xs={ 5 } className="card-subtitle">
                                                        £{ "500" } p/w
                                                    </Col>
                                                    <Col>
                                                        <div className="card-footer-icons">
                                                            <div className="count-icons">
                                                                <i className="icon icon-bedroom"></i>
                                                                <span>{ "2" }</span>
                                                            </div>
                                                            <div className="count-icons">
                                                                <i className="icon icon-bathroom"></i>
                                                                <span>{ "3" }</span>
                                                            </div>
                                                            <div className="count-icons">
                                                                <i className="icon icon-pet-friendly"></i>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </Slider>
                            </Col>
                        </Row>
                        
                        <Row>
                            <Col lg={12}>
                                <div className="d-flex justify-content-between available-properties-wrapper mb-4">
                                    <div className="available-properties">2 Bedroom Properties <span>(4 Available)</span></div>
                                    <div className="available-price">from <span>£795 p/w -</span></div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <Slider {...settings}>
                                    <div className="single-property-card-wrapper">
                                        <Card>
                                            <div className="card-container-image">
                                                <span className="image-label">
                                                    { "For Sale" }
                                                </span>
                                                <div class="card-container-image">
                                                    <img className={"img-fluid"} src={NoImage} alt={""} title={""} />
                                                </div>
                                            </div>
                                            <Card.Body>
                                                <Card.Title className="card-title">
                                                    { "Hill Street, W1, London" } 
                                                </Card.Title>
                                                <Row className="card-content">
                                                    <Col sm={ 5 } xs={ 5 } className="card-subtitle">
                                                        £{ "500" } p/w
                                                    </Col>
                                                    <Col>
                                                        <div className="card-footer-icons">
                                                            <div className="count-icons">
                                                                <i className="icon icon-bedroom"></i>
                                                                <span>{ "2" }</span>
                                                            </div>
                                                            <div className="count-icons">
                                                                <i className="icon icon-bathroom"></i>
                                                                <span>{ "3" }</span>
                                                            </div>
                                                            <div className="count-icons">
                                                                <i className="icon icon-pet-friendly"></i>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className="single-property-card-wrapper">
                                        <Card>
                                            <div className="card-container-image">
                                                <span className="image-label">
                                                    { "For Sale" }
                                                </span>
                                                <div class="card-container-image">
                                                    <img className={"img-fluid"} src={NoImage} alt={""} title={""} />
                                                </div>
                                            </div>
                                            <Card.Body>
                                                <Card.Title className="card-title">
                                                    { "Hill Street, W1, London" } 
                                                </Card.Title>
                                                <Row className="card-content">
                                                    <Col sm={ 5 } xs={ 5 } className="card-subtitle">
                                                        £{ "500" } p/w
                                                    </Col>
                                                    <Col>
                                                        <div className="card-footer-icons">
                                                            <div className="count-icons">
                                                                <i className="icon icon-bedroom"></i>
                                                                <span>{ "2" }</span>
                                                            </div>
                                                            <div className="count-icons">
                                                                <i className="icon icon-bathroom"></i>
                                                                <span>{ "3" }</span>
                                                            </div>
                                                            <div className="count-icons">
                                                                <i className="icon icon-pet-friendly"></i>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className="single-property-card-wrapper">
                                        <Card>
                                            <div className="card-container-image">
                                                <span className="image-label">
                                                    { "For Sale" }
                                                </span>
                                                <div class="card-container-image">
                                                    <img className={"img-fluid"} src={NoImage} alt={""} title={""} />
                                                </div>
                                            </div>
                                            <Card.Body>
                                                <Card.Title className="card-title">
                                                    { "Hill Street, W1, London" } 
                                                </Card.Title>
                                                <Row className="card-content">
                                                    <Col sm={ 5 } xs={ 5 } className="card-subtitle">
                                                        £{ "500" } p/w
                                                    </Col>
                                                    <Col>
                                                        <div className="card-footer-icons">
                                                            <div className="count-icons">
                                                                <i className="icon icon-bedroom"></i>
                                                                <span>{ "2" }</span>
                                                            </div>
                                                            <div className="count-icons">
                                                                <i className="icon icon-bathroom"></i>
                                                                <span>{ "3" }</span>
                                                            </div>
                                                            <div className="count-icons">
                                                                <i className="icon icon-pet-friendly"></i>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className="single-property-card-wrapper">
                                        <Card>
                                            <div className="card-container-image">
                                                <span className="image-label">
                                                    { "For Sale" }
                                                </span>
                                                <div class="card-container-image">
                                                    <img className={"img-fluid"} src={NoImage} alt={""} title={""} />
                                                </div>
                                            </div>
                                            <Card.Body>
                                                <Card.Title className="card-title">
                                                    { "Hill Street, W1, London" } 
                                                </Card.Title>
                                                <Row className="card-content">
                                                    <Col sm={ 5 } xs={ 5 } className="card-subtitle">
                                                        £{ "500" } p/w
                                                    </Col>
                                                    <Col>
                                                        <div className="card-footer-icons">
                                                            <div className="count-icons">
                                                                <i className="icon icon-bedroom"></i>
                                                                <span>{ "2" }</span>
                                                            </div>
                                                            <div className="count-icons">
                                                                <i className="icon icon-bathroom"></i>
                                                                <span>{ "3" }</span>
                                                            </div>
                                                            <div className="count-icons">
                                                                <i className="icon icon-pet-friendly"></i>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className="single-property-card-wrapper">
                                        <Card>
                                            <div className="card-container-image">
                                                <span className="image-label">
                                                    { "For Sale" }
                                                </span>
                                                <div class="card-container-image">
                                                    <img className={"img-fluid"} src={NoImage} alt={""} title={""} />
                                                </div>
                                            </div>
                                            <Card.Body>
                                                <Card.Title className="card-title">
                                                    { "Hill Street, W1, London" } 
                                                </Card.Title>
                                                <Row className="card-content">
                                                    <Col sm={ 5 } xs={ 5 } className="card-subtitle">
                                                        £{ "500" } p/w
                                                    </Col>
                                                    <Col>
                                                        <div className="card-footer-icons">
                                                            <div className="count-icons">
                                                                <i className="icon icon-bedroom"></i>
                                                                <span>{ "2" }</span>
                                                            </div>
                                                            <div className="count-icons">
                                                                <i className="icon icon-bathroom"></i>
                                                                <span>{ "3" }</span>
                                                            </div>
                                                            <div className="count-icons">
                                                                <i className="icon icon-pet-friendly"></i>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </Slider>
                            </Col>
                        </Row>


                        <Row>
                            <Col lg={12}>
                                <div className="d-flex justify-content-between available-properties-wrapper mb-4">
                                    <div className="available-properties">3 Bedroom Properties <span>(5 Available)</span></div>
                                    <div className="available-price">from <span>£995 p/w -</span></div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <Slider {...settings}>
                                    <div className="single-property-card-wrapper">
                                        <Card>
                                            <div className="card-container-image">
                                                <span className="image-label">
                                                    { "For Sale" }
                                                </span>
                                                <div class="card-container-image">
                                                    <img className={"img-fluid"} src={NoImage} alt={""} title={""} />
                                                </div>
                                            </div>
                                            <Card.Body>
                                                <Card.Title className="card-title">
                                                    { "Hill Street, W1, London" } 
                                                </Card.Title>
                                                <Row className="card-content">
                                                    <Col sm={ 5 } xs={ 5 } className="card-subtitle">
                                                        £{ "500" } p/w
                                                    </Col>
                                                    <Col>
                                                        <div className="card-footer-icons">
                                                            <div className="count-icons">
                                                                <i className="icon icon-bedroom"></i>
                                                                <span>{ "2" }</span>
                                                            </div>
                                                            <div className="count-icons">
                                                                <i className="icon icon-bathroom"></i>
                                                                <span>{ "3" }</span>
                                                            </div>
                                                            <div className="count-icons">
                                                                <i className="icon icon-pet-friendly"></i>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className="single-property-card-wrapper">
                                        <Card>
                                            <div className="card-container-image">
                                                <span className="image-label">
                                                    { "For Sale" }
                                                </span>
                                                <div class="card-container-image">
                                                    <img className={"img-fluid"} src={NoImage} alt={""} title={""} />
                                                </div>
                                            </div>
                                            <Card.Body>
                                                <Card.Title className="card-title">
                                                    { "Hill Street, W1, London" } 
                                                </Card.Title>
                                                <Row className="card-content">
                                                    <Col sm={ 5 } xs={ 5 } className="card-subtitle">
                                                        £{ "500" } p/w
                                                    </Col>
                                                    <Col>
                                                        <div className="card-footer-icons">
                                                            <div className="count-icons">
                                                                <i className="icon icon-bedroom"></i>
                                                                <span>{ "2" }</span>
                                                            </div>
                                                            <div className="count-icons">
                                                                <i className="icon icon-bathroom"></i>
                                                                <span>{ "3" }</span>
                                                            </div>
                                                            <div className="count-icons">
                                                                <i className="icon icon-pet-friendly"></i>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className="single-property-card-wrapper">
                                        <Card>
                                            <div className="card-container-image">
                                                <span className="image-label">
                                                    { "For Sale" }
                                                </span>
                                                <div class="card-container-image">
                                                    <img className={"img-fluid"} src={NoImage} alt={""} title={""} />
                                                </div>
                                            </div>
                                            <Card.Body>
                                                <Card.Title className="card-title">
                                                    { "Hill Street, W1, London" } 
                                                </Card.Title>
                                                <Row className="card-content">
                                                    <Col sm={ 5 } xs={ 5 } className="card-subtitle">
                                                        £{ "500" } p/w
                                                    </Col>
                                                    <Col>
                                                        <div className="card-footer-icons">
                                                            <div className="count-icons">
                                                                <i className="icon icon-bedroom"></i>
                                                                <span>{ "2" }</span>
                                                            </div>
                                                            <div className="count-icons">
                                                                <i className="icon icon-bathroom"></i>
                                                                <span>{ "3" }</span>
                                                            </div>
                                                            <div className="count-icons">
                                                                <i className="icon icon-pet-friendly"></i>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className="single-property-card-wrapper">
                                        <Card>
                                            <div className="card-container-image">
                                                <span className="image-label">
                                                    { "For Sale" }
                                                </span>
                                                <div class="card-container-image">
                                                    <img className={"img-fluid"} src={NoImage} alt={""} title={""} />
                                                </div>
                                            </div>
                                            <Card.Body>
                                                <Card.Title className="card-title">
                                                    { "Hill Street, W1, London" } 
                                                </Card.Title>
                                                <Row className="card-content">
                                                    <Col sm={ 5 } xs={ 5 } className="card-subtitle">
                                                        £{ "500" } p/w
                                                    </Col>
                                                    <Col>
                                                        <div className="card-footer-icons">
                                                            <div className="count-icons">
                                                                <i className="icon icon-bedroom"></i>
                                                                <span>{ "2" }</span>
                                                            </div>
                                                            <div className="count-icons">
                                                                <i className="icon icon-bathroom"></i>
                                                                <span>{ "3" }</span>
                                                            </div>
                                                            <div className="count-icons">
                                                                <i className="icon icon-pet-friendly"></i>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className="single-property-card-wrapper">
                                        <Card>
                                            <div className="card-container-image">
                                                <span className="image-label">
                                                    { "For Sale" }
                                                </span>
                                                <div class="card-container-image">
                                                    <img className={"img-fluid"} src={NoImage} alt={""} title={""} />
                                                </div>
                                            </div>
                                            <Card.Body>
                                                <Card.Title className="card-title">
                                                    { "Hill Street, W1, London" } 
                                                </Card.Title>
                                                <Row className="card-content">
                                                    <Col sm={ 5 } xs={ 5 } className="card-subtitle">
                                                        £{ "500" } p/w
                                                    </Col>
                                                    <Col>
                                                        <div className="card-footer-icons">
                                                            <div className="count-icons">
                                                                <i className="icon icon-bedroom"></i>
                                                                <span>{ "2" }</span>
                                                            </div>
                                                            <div className="count-icons">
                                                                <i className="icon icon-bathroom"></i>
                                                                <span>{ "3" }</span>
                                                            </div>
                                                            <div className="count-icons">
                                                                <i className="icon icon-pet-friendly"></i>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </Slider>
                            </Col>
                        </Row>
                    </div>                    
                </Container>
            </SectionWrapper>
        </React.Fragment>
    )
}

export default AvailableApartments