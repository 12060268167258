import * as React from "react"
import { Container, Row, Col } from "react-bootstrap"

// Styles
// import "./AreaGuide.scss"
import CustomButton from "../../../components/CustomButton"

// Components
import SectionWrapper from "../../../components/SectionWrapper"
import SectionHeader from "../../../components/SectionHeader"

const AboutUs = ( { aboutUsData } ) => {
    return (
        <SectionWrapper className="areaguide-wrapper">
            <Container>
                <Row>
                    <Col>
                        <div className="content-wrapper">
                            <SectionHeader
                                sectionHeaderData={ {
                                    Title: 'About Us',
                                } }
                            />
                            <div className="subtitle">
                            As a Prime Central London Landlord specialising in rentals, sales and acquisitions, we are fluent in understanding the value of each specific area.
                            </div>
                            <div
                                className="content"
                            >
                                Our unique knowledge of our territories allows us to make fast decisions and act quickly on opportunities offered to us.<br /><br />
                                We offer the widest selection of solely owned properties to rent in a variety of buildings across Central London. We are a responsible Landlord with the widest selection of rental properties in the Capital, all managed in-house by a dedicated team.
                            </div>
                            <div>
                                <CustomButton
                                    value="VIEW MORE"
                                    variant="outline"
                                />
                            </div>
                        </div>
                    </Col>
                    <Col
                        lg={ 7 }
                    >
                        <div className="video-container">
                            <img
                                src={ require( `../../../images/area-guide.png` ).default }
                                alt="about-us"
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </SectionWrapper>
    )
}

export default AboutUs
