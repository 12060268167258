import React, { useState, useEffect } from "react"
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"

// Temporary Data
import commonData from "../data/common-data.json"
import propertyDetails from "../data/property-details.json"

// Components
import Header from "../components/Header"
import BreadCrumbs from "../components/BreadCrumbs"
import PropertyOverview from "../layout/PropertyDetails/PropertyOverview"
import Description from "../layout/PropertyDetails/Description"
import BroadbandDetails from "../layout/PropertyDetails/BroadbandDetails"
import LocRatingMap from "../components/LocratingMap/locrating-map-details"
import LocationDetails from "../layout/PropertyDetails/LocationDetails"
import AreaGuide from "../layout/PropertyDetails/AreaGuide"
import AboutUs from "../layout/PropertyDetails/AboutUs"
import AvailableApartments from "../layout/PropertyDetails/AvailableApartments"
import SimilarProperties from "../layout/PropertyDetails/SimilarProperties"
import Footer from "../components/Footer"
import BrandLogo from "../images/resiland-logo.svg";

const PROPERTY_DETAILS = gql`
  query GetProperty ($id: String!){  
    properties(where:{id: $id}) {
        id
        status
        department
        search_type
        display_address
        building
        title
        price
        price_qualifier
        bedroom
        bathroom
        reception
        latitude
        longitude
    }
  }
`;

const PropertyDetailsDevelopmentTemplate = (props) => {

    // 
    const pageurl = typeof window !== 'undefined' ? window.location.href : ''
    let myid_filt = pageurl.split('?');
    let mypageurl = myid_filt[0];
    let property_slug = mypageurl.split('-');
    let id = property_slug[property_slug.length - 1];
    id = id.replace("/", "");

    const { loading, error, data: property_details } = useQuery(PROPERTY_DETAILS, {
        variables: { id: id }
    });

    // Loading logo 
    if (loading) return (
        <section className={"loader-wrapper"}>
            <div id="loader-wrapper">
                <div id="loader" class="new-loader">
                    <img className="logo-white loader-logo" src={BrandLogo} alt="logo"/>
                </div>
            </div>
        </section>
    );
    // Loading logo
    // 

    return (
        <React.Fragment>
            <div className="page-wrapper">
                <Header headerData={ commonData } />
                {
                    property_details && property_details.properties && property_details.properties.length > 0 ? property_details.properties.map((data, i) => {

                        // Get Map Details
                        let mapItems = [];
                        let mapItem = {};
                        mapItem['lat']  = data.latitude      
                        mapItem['lng']  = data.longitude
                        mapItem['id']  = data.id
                        const mapMarkerImageShowFlag = 0;
                        mapItems.push( mapItem );
                        // Get Map Details

                        return (
                            <>
                                <BreadCrumbs
                                    breadCrumbsData={ 
                                        [{
                                            name: 'Home',
                                            link: '/'
                                        },
                                        {
                                            name: data?.display_address && data?.display_address,
                                            // link: '#'
                                        },
                                        {
                                            name: data?.title && data?.title,
                                            // link: '#'
                                        },]
                                     }
                                />

                                <PropertyOverview 
                                    propertyData={data} 
                                    propertyTag={"development"}
                                />
                                <Description 
                                    propertyData={data} 
                                    propertyTag={"development"}
                                />
                                <AvailableApartments propertyData={data} />
                                <AboutUs />
                                <div className="property-details-landing-map" id="property-details-map" data-item="property-details-map">
                                    <LocRatingMap data={mapItems} />
                                </div>
                                <AreaGuide />
                                <SimilarProperties similarPropertiesData={ propertyDetails.SimilarProperties } />
                            </>
                        )
                    }) : ''
                }
                <Footer
                    footerData={ commonData.FooterMenu }
                    footerTermsMenu={ commonData.FooterTermsMenu }
                    footerSocialLinks={ commonData.FooterSocialLinks }
                    footerCompanies={ commonData.FooterCompanies }
                />
            </div>
        </React.Fragment>
    )
}

export default PropertyDetailsDevelopmentTemplate